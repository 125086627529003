<template>
    <div class="edit-view">
        <div class="userTitle">
            <span>{{ formData.id ? $t('bian-ji') : $t('xin-zeng') }}{{ $t('xin-wen') }}</span>
            <p class="dividingLine"></p>
        </div>
        <div class="main">
            <el-form
                :model="formData"
                :rules="rules"
                ref="form"
                label-width="1.8rem"
                label-position="right"
                size="small"
                style="max-width: 9.8rem;"
            >
                <el-form-item prop="type" :label="$t('fen-lei')">
                    <el-select v-model="formData.type" clearable filterable :placeholder="$t('qing-xuan-ze')">
                        <el-option v-for="item in typeInfos" :key="item.key" :label="item.name" :value="item.key">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="img" :label="$t('xin-wen-feng-mian')" :rules="rulesRequire">
                    <crop-upload
                        v-model="formData.img"
                        :width="700"
                        :height="500"
                        :ratio="[7, 5]"
                        :imgWidth="245"
                        name="img"
                    >
                        <div slot="tips" class="tips">
                            {{
                                $t(
                                    'tu-pian-chi-cun-bu-di-yu-700x500px-zhi-chi-jpgpng-ge-shi-tu-pian-da-xiao-bu-chao-guo-1m'
                                )
                            }}
                        </div>
                    </crop-upload>
                </el-form-item>
                <!--<el-form-item prop="video" label="视频">
                <el-input v-model="formData.video"></el-input>
            </el-form-item>-->
                <el-form-item prop="title" :label="$t('xin-wen-biao-ti')" :rules="rulesRequire">
                    <el-input
                        v-model="formData.title"
                        maxlength="30"
                        :placeholder="$t('qing-shu-ru-xin-wen-biao-ti-bu-chao-guo-30-zi')"
                    ></el-input>
                </el-form-item>
                <el-form-item prop="introduction" :label="$t('xin-wen-jian-jie')">
                    <el-input
                        type="textarea"
                        :autosize="{ minRows: 4, maxRows: 8 }"
                        :maxlength="60"
                        :placeholder="$t('qing-shu-ru-xin-wen-jian-jie-bu-chao-guo-60-zi')"
                        v-model="formData.introduction"
                    ></el-input>
                </el-form-item>
                <el-form-item prop="content" :label="$t('xin-wen-xiang-qing')">
                    <rich-text v-model="formData.content" style="width: 880px;"></rich-text>
                </el-form-item>
                <el-form-item :label="$t('re-men-tui-jian')">
                    <el-switch v-model="first" @change="formData.sort = first ? 0 : ''"> </el-switch>
                </el-form-item>
                <!-- <el-form-item poro="type" label="类别">
                    <el-radio-group v-model="formData.type">
                        <el-radio v-for="item in types" :key="item.value" :label="item.value">{{
                            item.label
                        }}</el-radio>
                    </el-radio-group>
                </el-form-item> -->
                <el-form-item>
                    <el-button @click="onSave" size="small" :loading="saving" type="warning">{{
                        $t('ti-jiao')
                    }}</el-button>
                    <el-button @click="onDelete" size="small" :loading="saving" type="danger" v-if="formData.id"
                        >{{ $t('shan-chu') }}
                    </el-button>
                    <el-button v-else @click="saveStorage" :loading="saving">{{ $t('bao-cun') }}</el-button>
                    <el-button @click="$router.go(-1)" size="small">{{ $t('fan-hui') }}</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
import formPage from '../../mixins/formPage';
import { InformationTypeEdit } from '../../utils/AppState';
export default {
    name: 'SuccessCaseEdit',
    mixins: [formPage],
    created() {
        if (this.$route.query.id) {
            this.$http
                .get('information/get/' + this.$route.query.id)
                .then(res => {
                    this.formData = res;
                    if (this.$route.query.first) {
                        this.first = true;
                    }
                })
                .catch(e => {
                    console.log(e);
                    this.$message.error(e.error);
                });
        } else if (window.localStorage.getItem('newsEdit_' + this.$store.state.userInfo.id)) {
            const caseEdit = JSON.parse(window.localStorage.getItem('newsEdit_' + this.$store.state.userInfo.id));
            this.formData = {
                ...caseEdit.formData,
                isHomeShow:caseEdit.formData.isHomeShow||false
            };
            this.first = caseEdit.first;
        }
    },
    data() {
        return {
            saving: false,
            formData: {
                type: 'INDUSTRY_NEWS',
                isHomeShow: false
            },
            rules: {},
            types: [
                { label: '案例', value: 'CASE' },
                { label: '新闻', value: 'NEWS' }
            ],
            first: false,
            types: ['INDUSTRY_NEWS', 'NEW_PRODUCT_RELEASE', 'TECHNICAL_INFORMATION']
        };
    },
    computed: {
        typeInfos() {
            return [...this.types].map(item => {
                return {
                    name: this.$t(InformationTypeEdit.get(item)?.name),
                    key: item
                };
            });
        }
    },
    methods: {
        onSave() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.submit();
                } else {
                    return false;
                }
            });
        },
        submit() {
            let data = { ...this.formData, status: 'PENDING' };

            if (!data.id) {
                data.vendorInfoId = this.$store.state.buyersInfo.vendorId;
                window.localStorage.removeItem('newsEdit_' + this.$store.state.userInfo.id);
            }

            this.saving = true;
            this.$http
                .post('/information/save', data, { body: 'json' })
                .then(res => {
                    this.saving = false;
                    this.$message.success(this.$t('cheng-gong'));
                    this.$router.go(-1);
                })
                .catch(e => {
                    console.log(e);
                    this.saving = false;
                    this.$message.error(e.error);
                });
        },
        onDelete() {
            this.$alert(this.$t('shan-chu-jiang-wu-fa-hui-fu-que-ren-yao-shan-chu-mo'), this.$t('jing-gao'), {
                type: 'error'
            })
                .then(() => {
                    return this.$http.post(`/successCase/del/${this.formData.id}`);
                })
                .then(() => {
                    this.$message.success(this.$t('shan-chu-cheng-gong'));
                    this.$router.go(-1);
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        console.log(e);
                        this.$message.error(e.error);
                    }
                });
        },
        saveStorage() {
            const caseEdit = {
                formData: this.formData,
                first: this.first
            };
            window.localStorage.setItem('newsEdit_' + this.$store.state.userInfo.id, JSON.stringify(caseEdit));
            this.$message.success(this.$t('bao-cun-cheng-gong'));
            history.back();
        }
    }
};
</script>
<style lang="scss" scoped>
.main {
    padding: 30px 0;

    .el-form {
        margin: 0;
    }
}

.el-button {
    min-width: 120px;
    height: 40px;

    &.el-button--warning {
        min-width: 160px;
    }
}
.userTitle {
    border: none;
}
.dividingLine {
    height: 1px;
    background-color: #dcdfe6;
}
</style>
